import React, { useState, useEffect } from 'react';
import { Map, List, fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import { usePrevious } from 'hooks';
import { setBrowseInfo } from 'actions/browse';
import ProductDetails from 'components/Product/ProductDetails';
import KioskProductDetails from 'components/KioskProductDetails';
import FavouriteButton from 'components/Product/ProductAdditional/FavouriteButton';
import { AlertFooter } from 'assets/styles/sharedStyles';
import ProductQuantity from 'components/ProductQuantity';
import { customiseProduct, addProductToOrder } from 'actions/order';
import { fetchSection } from 'actions/menu';
import {
  selectMenuType,
  productHasModifiers,
  selectDefaultModifiers,
  showInstantAddButton,
  getItemPrice,
  getMenuSectionById,
  selectSectionProducts,
  backToItemMenuPage,
  getProductById,
} from 'selectors/browse';
import {
  getVenueId,
  getServiceId,
  getServiceById,
  isDeliveryToAddressEnabled,
  isServiceBrowseOnly,
} from 'selectors/root';
import { shouldAllowItemNotes } from 'selectors/features';
import { openEnterNameAlert, openRadiusAlert } from 'actions/UI';
import { isLoggedIn, selectIsUserAddressInRange } from 'selectors/user';
import { reactPixel, gtmDataLayerPush } from 'utils';
import { trackEvent } from 'utils/tracking';
import {
  InfoContainer,
  StyledContent,
  ButtonsContainer,
  FlexBox,
  StyledActionButtons,
  ButtonsWrapper,
} from './styles';
import { AddToOrder } from './AddToOrder';
import { Notes } from './Notes';
import { Customise } from './Customise';

const messages = defineMessages({
  removeOption: {
    defaultMessage: 'Remove',
  },
  customise: {
    defaultMessage: 'Customise',
  },
  customiseOptions: {
    defaultMessage: 'Customisation Options',
  },
});
const propTypes = {
  defaultModifiers: PropTypes.instanceOf(List),
  product: PropTypes.instanceOf(Map).isRequired,
  pageView: PropTypes.bool,
  slimProducts: PropTypes.bool,
  fetchSection: PropTypes.func.isRequired,
  sectionId: PropTypes.string,
  setBrowseInfo: PropTypes.func,
  item: PropTypes.instanceOf(Map).isRequired,
  menuType: PropTypes.object,
  serviceId: PropTypes.string,
  venueId: PropTypes.number,
  customiseProduct: PropTypes.func,
  addToOrder: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  isDeliveryToAddressEnabled: PropTypes.bool,
  isAddressUserInRange: PropTypes.bool,
  getItemPrice: PropTypes.func,
  browseOnly: PropTypes.bool,
  allowItemNotes: PropTypes.bool,
  openRadiusAlert: PropTypes.func,
  closeAlert: PropTypes.func,
  backToItemMenuPage: PropTypes.func,
  openEnterNameAlert: PropTypes.func,
};
const ProductOptionInformation = ({
  defaultModifiers,
  product,
  pageView,
  slimProducts,
  fetchSection,
  sectionId,
  venueId,
  setBrowseInfo,
  item,
  menuType,
  serviceId,
  customiseProduct,
  addToOrder,
  isLoggedIn,
  isDeliveryToAddressEnabled,
  isAddressUserInRange,
  getItemPrice,
  browseOnly,
  allowItemNotes,
  openRadiusAlert,
  closeAlert,
  backToItemMenuPage,
  openEnterNameAlert,
}) => {
  // Add venueId to array to turn on kiosk product details component
  const kiosk = [111, 1477, 1295, 1657, 1658, 1659, 1660, 1661, 1807, 1808].includes(venueId);

  const [notes, setNotes] = useState('');

  const [itemEnteredName, setItemEnteredName] = useState('');

  // Productquantity
  const [quantity, setQuantity] = useState(1);

  // Modifies
  const [defaultMods, setDefaultMods] = useState(defaultModifiers || null);

  // Product
  const [prod, setProd] = useState(product);

  // Slim products
  const [prevSlimProducts] = usePrevious(slimProducts);

  const [addedToBasket, setAddedToBasket] = useState(false);

  useEffect(() => {
    if (pageView && slimProducts) fetchSection(sectionId);
  }, [pageView, slimProducts, fetchSection, sectionId]);
  useEffect(() => {
    if (prevSlimProducts && !slimProducts) setDefaultMods(defaultModifiers);
  }, [prevSlimProducts, slimProducts, defaultModifiers]);

  useEffect(() => {
    if (itemEnteredName) {
      addToOrderCallback();
    }
  }, [itemEnteredName]);

  useEffect(() => {
    if (addedToBasket) {
      closeAlert();
    }
  });

  // Modifiers
  useEffect(() => {
    const prod = baseModifierValue ? baseModifierValue.get('product') : product;
    setProd(prod);

    if (pageView) {
      setBrowseInfo(prod.get('name'), '', prod.getIn(['images', 0, 'path']));
    }
  }, [baseModifierValue, product, defaultMods, pageView, setBrowseInfo]);
  const updateBaseModifierValue = value => {
    setDefaultMods(prevStateDefaultMods => {
      const defaultMods = prevStateDefaultMods;
      const index = defaultMods.findIndex(
        modifier => modifier.getIn(['modifier', 'display_type']) === 'base'
      );
      return defaultMods.setIn([index, 'values'], fromJS([value]));
    });
  };
  const baseModifier = defaultMods
    ? defaultMods.find(modifier => modifier.getIn(['modifier', 'display_type']) === 'base')
    : null;

  const baseModifierValue = baseModifier?.getIn(['values', 0]);

  // Customise
  const handleCustomiseProduct = () => {
    reactPixel.track('CustomizeProduct');
    gtmDataLayerPush('CustomizeProduct');

    customiseProduct(item, menuType, serviceId, quantity, notes, defaultMods, prod);
  };

  // Handling add to order
  const handleAddToOrder = () => {
    reactPixel.track('AddToCart', { content_name: prod?.get('name', '') });
    gtmDataLayerPush('AddToCart', { content_name: prod?.get('name', '') });

    const finalNotes = itemEnteredName ? itemEnteredName + (notes ? `\n${notes}` : '') : notes;

    addToOrder(item, menuType, serviceId, finalNotes, quantity, defaultMods);
  };

  // Unit price
  let unitPrice = menuType?.get('id')
    ? item.getIn(['prices', menuType.get('id').toString(), 'price'])
    : undefined;
  if (baseModifierValue) {
    unitPrice = baseModifierValue.getIn(['product', 'price']);
  }

  // Product price
  const price = getItemPrice(unitPrice, prod.get('tax_categories')) || undefined;

  const showRadiusModal = !isLoggedIn && isDeliveryToAddressEnabled && !isAddressUserInRange;

  const showCustomise = productHasModifiers(prod, true) || productHasModifiers(item.get('product'), true);

  const showAddToOrder =
    !browseOnly && (!showCustomise || (showInstantAddButton(product) && showInstantAddButton(prod)));

  const addToOrderCallback = () => {
    if (!itemEnteredName && item.getIn(['product', 'tags'])?.includes('requires-name')) {
      openEnterNameAlert(setItemEnteredName);

      return;
    }

    if (showRadiusModal) {
      openRadiusAlert();

      return;
    }

    handleAddToOrder();

    if (closeAlert) closeAlert();
    else if (pageView) backToItemMenuPage();

    setAddedToBasket(true);
  };

  const customiseCallback = () => {
    showRadiusModal ? openRadiusAlert() : handleCustomiseProduct();
    if (showInstantAddButton(product)) {
      trackEvent('customise_item', {
        category: 'item',
      });
    }
  };

  const customiseLabel = browseOnly ? (
    <FormattedMessage {...messages.customiseOptions} />
  ) : (
    <FormattedMessage {...messages.customise} />
  );

  if (!kiosk)
    return (
      <>
        <InfoContainer pageView={pageView}>
          <StyledContent pageView={pageView}>
            <ProductDetails
              product={prod}
              baseModifier={baseModifier?.get('modifier')}
              selectedBaseModifierId={baseModifierValue?.get('id')}
              changeModifier={updateBaseModifierValue}
              price={price}
              productImages={pageView ? new List() : prod.get('images')}
              productDescription={prod.get('description')}
              pageView={pageView}
              additionalButtons={[
                <FavouriteButton smallBtn={pageView} key={product.get('id')} product={product} />,
              ]}
            />
          </StyledContent>
          <AlertFooter>
            <ButtonsContainer>
              {!browseOnly && allowItemNotes && <Notes onChange={setNotes} value={notes} />}
              <FlexBox allowItemNotes={allowItemNotes}>
                <ProductQuantity
                  quantity={quantity}
                  onChange={value => setQuantity(value)}
                  productId={product?.get('id')}
                />
              </FlexBox>
            </ButtonsContainer>
          </AlertFooter>
        </InfoContainer>
        <StyledActionButtons pageView={pageView}>
          {showCustomise && <Customise callback={customiseCallback} label={customiseLabel} />}
          {showAddToOrder && <AddToOrder callback={addToOrderCallback} />}
        </StyledActionButtons>
      </>
    );

  return (
    <>
      <InfoContainer pageView={pageView}>
        <StyledContent pageView={pageView}>
          <KioskProductDetails
            product={prod}
            baseModifier={baseModifier?.get('modifier')}
            selectedBaseModifierId={baseModifierValue?.get('id')}
            changeModifier={updateBaseModifierValue}
            price={price}
            productImages={pageView ? new List() : prod.get('images')}
            productDescription={prod.get('description')}
            pageView={pageView}
            additionalButtons={[
              <FavouriteButton smallBtn={pageView} key={product.get('id')} product={product} />,
            ]}
            quantity={
              <ProductQuantity
                quantity={quantity}
                onChange={value => setQuantity(value)}
                productId={product?.get('id')}
              />
            }
          />
        </StyledContent>
        {/* <Notes /> */}
      </InfoContainer>
      <ButtonsWrapper>
        {showCustomise && <Customise callback={customiseCallback} label={customiseLabel} />}
        {showAddToOrder && <AddToOrder callback={addToOrderCallback} />}
      </ButtonsWrapper>
    </>
  );
};
ProductOptionInformation.propTypes = propTypes;
const mapStateToProps = (state, ownProps) => {
  let item = ownProps?.item;
  let section;
  const serviceId = getServiceId(state);
  const venueId = getVenueId(state);
  const productId = ownProps.params?.productId;
  const menuId = ownProps.params?.menuId;
  const sectionId = ownProps.params?.sectionId;
  if (!item && productId) {
    section = getMenuSectionById(state, menuId, sectionId);
    const items = selectSectionProducts(state, section, getServiceById(state, serviceId));
    item = items.find(item => item.get('productId') === Number(productId));
  }
  const product = getProductById(state, item.get('productId'));
  return {
    isLoggedIn: isLoggedIn(state),
    isDeliveryToAddressEnabled: isDeliveryToAddressEnabled(state),
    isAddressUserInRange: selectIsUserAddressInRange(state),
    pageView: Boolean(productId),
    sectionId,
    item,
    product,
    slimProducts: section && section.get('slimProducts'),
    menuType: selectMenuType(state),
    serviceId,
    defaultModifiers: selectDefaultModifiers(product),
    getItemPrice: (price, taxCategories) => getItemPrice(state, price, taxCategories),
    backToItemMenuPage: () => backToItemMenuPage(state, item),
    allowItemNotes: shouldAllowItemNotes(state),
    browseOnly: isServiceBrowseOnly(state, serviceId),
    venueId,
  };
};

const mapDispatchToProps = dispatch => ({
  setBrowseInfo: (title, description, imagePath) => dispatch(setBrowseInfo(title, description, imagePath)),
  openRadiusAlert: () => dispatch(openRadiusAlert()),
  openEnterNameAlert: setName => dispatch(openEnterNameAlert(setName)),
  fetchSection: id => dispatch(fetchSection(id)),
  customiseProduct: (
    item,
    menuType,
    serviceId,
    quantity,
    notes,
    modifiers = undefined,
    baseModifierProduct = undefined
  ) => {
    item = item.merge({
      quantity,
      notes,
      modifiers,
      // The skeleton / parent product id
      base_product_id: item.get('productId'),
      // The product that has been selected in the base modifier selection
      productId: baseModifierProduct.get('id'),
    });

    dispatch(customiseProduct(item, menuType, serviceId, 'customiseFromOnInfo'));
  },
  addToOrder: (item, menuType, serviceId, notes, quantity, modifiers = undefined) =>
    dispatch(addProductToOrder(item, menuType, serviceId, modifiers, notes, quantity)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductOptionInformation);
